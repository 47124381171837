<template>
    <div class="row">
        <div class="col-md-12">
            <b-card-group deck>
                <b-card header-tag="header" footer-tag="footer">
                    <template v-slot:header>
                        <h6 class="mb-0">Danh sách giao dịch</h6>
                    </template>
                    <div class="filter-transaction">
                        <div>
                            <div class="row mb-6">
                                <div class="col-lg-4 mb-3 mb-6">
                                    <label>Từ ngày:</label>
                                    <div class="input-daterange input-group" id="kt_datepicker">
                                        <input
                                            type="date"
                                            v-model="filter.start_date"
                                            class="form-control datatable-input"
                                            name="start"
                                            placeholder="Từ"
                                            @input="handleInput"
                                        />
                                        <div class="input-group-append">
                                            <span class="input-group-text">
                                              <i class="la la-ellipsis-h"></i>
                                            </span>
                                        </div>
                                        <input
                                            type="date"
                                            v-model="filter.end_date"
                                            class="form-control datatable-input"
                                            name="end"
                                            placeholder="Đến"
                                            @input="handleInput"
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-2  mb-3 mb-6">
                                    <label>SDT:</label>
                                    <input
                                        type="text"
                                        v-model="filter.phone"
                                        class="form-control"
                                        name="phone"
                                    />
                                </div>
                                <div class="col-lg-2  mb-3 mb-6">
                                    <label>Liên kết bank:</label>
                                    <select
                                        class="form-control datatable-input"
                                        data-col-index="6"
                                        v-model="filter.link_bank"
                                    >
                                        <option value="">Tất cả</option>
                                        <option value="1">Đã liên kết</option>
                                        <option value="0">Chưa liên kết</option>
                                    </select>
                                </div>
                                <div class="col-lg-2  mb-3 mb-6">
                                    <label>KYC:</label>
                                    <select
                                        class="form-control datatable-input"
                                        data-col-index="6"
                                        v-model="filter.kyc"
                                    >
                                        <option value="">Tất cả</option>
                                        <option value="1">Đã kyc</option>
                                        <option value="0">Chưa kyc</option>
                                    </select>
                                </div>
                                <div class="col-lg-2  mb-3 mb-6">
                                    <label>Trạng thái GD:</label>
                                    <select
                                        class="form-control datatable-input"
                                        data-col-index="7"
                                        v-model="filter.status"
                                    >
                                        <option value="">Tất cả</option>
                                        <option value="1">Đã có gd</option>
                                        <option value="2">Chưa có gd</option>
                                    </select>
                                </div>
                                <div class="col-lg-4  mb-3 mb-6">
                                    <label>GameID:</label>
                                    <multiselect
                                        v-model="filter.game_id"
                                        :options="games"
                                        placeholder="Chọn game"
                                        label="app_name"
                                        :multiple="true"
                                        track-by="merchant_game_id">

                                    </multiselect>
                                </div>
                                <div class="col-lg-4  mb-3 mb-6">
                                    <label>CampID:</label>
                                    <multiselect
                                        v-model="filter.camp_id"
                                        :options="camps"
                                        placeholder="Chọn Camp"
                                        label="sub_description"
                                        :multiple="true"
                                        track-by="id">
                                    </multiselect>
                                </div>
                                <div class="col-lg-4  mb-3 mb-6" v-if="filter.status==1">
                                    <label>Sắp xếp:</label>
                                    <select
                                        class="form-control datatable-input"
                                        data-col-index="7"
                                        v-model="filter.sort"
                                    >
                                        <option value="">Mặc định</option>
                                        <option value="total_trans desc">Tổng GD (↓)</option>
                                        <option value="total_trans asc">Tổng GD (↑)</option>
                                        <option value="total_gmv desc">Tổng GMV (↓)</option>
                                        <option value="total_gmv asc">Tổng GMV (↑)</option>
                                        <option value="latest_trans_date desc">Last TransDate (↓)</option>
                                        <option value="latest_trans_date asc">Last TransDate (↑)</option>
                                        <option value="total_voucher desc">Budget spent (↓)</option>
                                        <option value="total_voucher asc">Budget spent (↑)</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-lg-12 text-right">
                                    <button
                                        class="btn btn-primary btn-primary--icon mr-2"
                                        @click="getList"
                                    >
                                      <span>
                                        <i class="la la-search"></i>
                                        <span>Tìm kiếm</span>
                                      </span>
                                    </button>
                                    <button
                                        class="btn btn-default btn-primary--icon mr-2"
                                        @click="resetCurrent"
                                    >
                                      <span>
                                        <span>Reset</span>
                                      </span>
                                    </button>
                                    <b-button
                                        class="mr-2"
                                        variant="outline-primary"
                                        @click="$bvModal.show('export-desc')"
                                    >Xuất excel
                                    </b-button>&nbsp;
                                </div>
                            </div>
                            <b-modal id="export-desc" title="Yêu cầu xuất dữ liệu">
                                <br/>
                                <div class="d-block text-center">
                                    <b-form-textarea
                                        placeholder="Nhập mô tả yêu cầu xuất dữ liệu ..."
                                        v-model="filter.exportDesc"
                                        rows="3"
                                    ></b-form-textarea>
                                </div>
                                <template #modal-footer="{ ok, cancel }">
                                    <b-button size="sm" variant="danger" @click="cancel()"> Hủy</b-button>
                                    <b-button size="sm" variant="success" @click="exportData()">
                                        Xuất
                                    </b-button>
                                </template>
                            </b-modal>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-lg-3 mb-3 mb-6" v-if="report.total_users > 0">
                            Tổng Users: {{numberFormat(report.total_users)}}
                        </div>
                        <div class="col-lg-3 mb-3 mb-6" v-if="report.total_trans > 0">
                            Tổng GD: {{numberFormat(report.total_trans)}}
                        </div>
                        <div class="col-lg-3 mb-3 mb-6" v-if="report.total_gmv > 0">
                            Tổng GMV: {{numberFormat(report.total_gmv)}}
                        </div>
                        <div class="col mb-3 mb-6" v-if="report.total_voucher > 0">
                            Budget spent: {{numberFormat(report.total_voucher)}}
                        </div>
                    </div>
                    <b-table
                        table-class="datatable-table"
                        hover
                        tbody-tr-class="datatable-row"
                        thead-tr-class="datatable-row"
                        thead-class="datatable-head"
                        details-td-class="datatable-cell"
                        :items="items"
                        :fields="fields"
                    >
                        <template #cell(stt)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell(total_gmv)="data">
                            {{ numberFormat(data.item.total_gmv) }}
                        </template>
                        <template #cell(total_trans)="data">
                            {{ numberFormat(data.item.total_trans) }}
                        </template>
                        <template #cell(total_voucher)="data">
                            {{ numberFormat(data.item.total_voucher) }}
                        </template>
                    </b-table>
                    <div class="mt-3">
                        <b-pagination-nav
                            v-model="currentPage"
                            :link-gen="createLinkPage"
                            :number-of-pages="paginate.totalPage"
                            use-router
                            first-number
                            last-number
                            align="right"
                        ></b-pagination-nav>
                    </div>
                </b-card>
            </b-card-group>
        </div>
    </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
Vue.component("multiselect", Multiselect);

const ReconcileRepository = RepositoryFactory.get("cms");
const TransactionRepository = RepositoryFactory.get("transactions");

export default {
    mixins: [Common],
    data() {
        return {
            totalPrice: 0,
            totalAmount: 0,
            filter: {
                status: '',
                keyword: '',
                kyc: null,
                link_bank: null,
                merchant_id: 0,
                game_id: 0,
                phone: '',
                service: '',
                camp_id: '',
                start_date: '',
                end_date: '',
                exportDesc: '',
                action: '',
                sort: '',
            },
            report:{
              total_gmv:0,
              total_trans:0,
              total_voucher:0,
                total_users:0,
            },
            fields: [
                {
                    key: "stt",
                    label: "STT",
                    tdClass: "datatable-cell",
                    thClass: "datatable-cell"
                },
                {
                    key: "phone",
                    label: "SDT",
                    tdClass: "datatable-cell",
                    thClass: "datatable-cell"
                },
                {
                    key: "total_trans",
                    label: "Số lượng giao dịch",
                    tdClass: "datatable-cell",
                    thClass: "datatable-cell",
                },
                {
                    key: "total_gmv",
                    label: "Tổng GMV",
                    tdClass: "datatable-cell",
                    thClass: "datatable-cell",
                },
                {
                    key: "latest_trans_date",
                    label: "Last TransDate",
                    tdClass: "datatable-cell",
                    thClass: "datatable-cell"
                },
                {
                    key: "last_login",
                    label: "Last login",
                    tdClass: "datatable-cell",
                    thClass: "datatable-cell"
                },
                {
                    key: "total_voucher",
                    label: "Budget spent",
                    tdClass: "datatable-cell",
                    thClass: "datatable-cell"
                },
            ],
            items: [],
            games:[],
            camps:[],
            currentPage: 1,
            currentPage1: 1,
            paginate: {
                limit: 20,
                page: 1,
                totalPage: 1,
                total: 0,
                skip: 0,
            },
        };
    },
    created(){
        this.listGames();
        this.listCamps();
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Thống kê giao dịch theo user", route: "list"}
        ]);
        this.filter = this.$route.query;
        this.filter.start_date =
            this.filter.start_date ?? this.firstDayOfMonth();
        this.filter.end_date =
            this.filter.end_date ?? this.lastDayOfMonth();

    },
    methods: {
        resetCurrent: function () {
            this.currentPage = 1;
            this.filter =  {
                status: '',
                keyword: '',
                phone: '',
                kyc: '',
                link_bank: '',
                merchant_id: null,
                game_id: null,
                service: '',
                camp_id: null,
                start_date: this.firstDayOfMonth(),
                end_date: this.lastDayOfMonth(),
                exportDesc: '',
                action: '',
                sort: '',
            }
        },
        async listGames(){
            let response = await TransactionRepository.listGames();
            if (response.data.error_code) {
                this.notifyAlert("warn", response.data.message);
                return;
            }
            let body = response.data;
            if (body.data.display) {
                this.games = body.data.display;
            } else {
                this.games = [];
            }
        },
        async listCamps(){

            let response = await TransactionRepository.listCamps();
            if (response.data.error_code) {
                this.notifyAlert("warn", response.data.message);
                return;
            }
            let body = response.data;
            if (body.data) {
                this.camps = body.data;
            } else {
                this.camps = [];
            }
        },
        async getList() {
            try {
                this.$bus.$emit("show-loading", true);
                let params = {...this.filter};
                params.page = this.currentPage;
                params.per_page = this.paginate.limit;
                let camps = this.filter.camp_id;
                let games = this.filter.game_id;
                let camp_ids = [];
                if(camps && camps.length > 0){
                    camps.forEach(function (item) {
                        camp_ids.push(item.sub_description);
                    })
                    params.camp_id = camp_ids;
                }

                let game_ids = [];
                if(games && games.length > 0){
                    games.forEach(function (item) {
                        if(item.merchant_game_id)
                            game_ids.push(item.merchant_game_id);
                    })
                    params.game_id = game_ids;
                }

                params = this.convert(params);
                let response = await ReconcileRepository.userTransactions(params);
                if (response.data.error_code) {
                    this.notifyAlert("warn", response.data.message);
                }
                this.$bus.$emit("show-loading", false);
                let body = response.data;
                if (body.data.data) {
                    this.items = body.data.data.data;
                    this.paginate.total = body.data.data.total;
                    this.paginate.totalPage = body.data.data.last_page;
                } else {
                    this.items = [];
                }
                this.getReportTotal();
            } catch (e) {
                this.$bus.$emit("show-loading", false);
            }
        },
        async getReportTotal() {
            try {
                this.$bus.$emit("show-loading", true);
                let params = {...this.filter};
                let camps = this.filter.camp_id;
                let games = this.filter.game_id;
                let camp_ids = [];
                if(camps && camps.length > 0){
                    camps.forEach(function (item) {
                        camp_ids.push(item.sub_description);
                    })
                    params.camp_id = camp_ids;
                }

                let game_ids = [];
                if(games && games.length > 0){
                    games.forEach(function (item) {
                        if(item.merchant_game_id)
                            game_ids.push(item.merchant_game_id);
                    })
                    params.game_id = game_ids;
                }

                params.report = 1;
                params = this.convert(params);
                // params.page = this.currentPage;
                // params.per_page = this.paginate.limit;
                let response = await ReconcileRepository.userTransactions(params);
                if (response.data.error_code) {
                    this.notifyAlert("warn", response.data.message);
                }
                this.$bus.$emit("show-loading", false);
                let body = response.data;
                if (body.data.data) {
                    this.report.total_gmv = body.data.data.total_gmv;
                    this.report.total_trans = body.data.data.total_trans;
                    this.report.total_voucher = body.data.data.total_voucher;
                    this.report.total_users = body.data.data.total_users;

                } else {
                    this.items = [];
                }
            } catch (e) {
                this.$bus.$emit("show-loading", false);
            }
        },
        exportData() {
            let params = this.filter;
            let list = {...this.filter};
            params = this.convert(list);
            let camps = this.filter.camp_id;
            let games = this.filter.game_id;
            let camp_ids = [];
            if(camps && camps.length > 0){
                camps.forEach(function (item) {
                    camp_ids.push(item.sub_description);
                })
                params.camp_id = camp_ids;
            }

            let game_ids = [];
            if(games && games.length > 0){
                games.forEach(function (item) {
                    if(item.merchant_game_id)
                        game_ids.push(item.merchant_game_id);
                })
                params.game_id = game_ids;
            }

            if (this.filter.exportDesc != null && this.filter.exportDesc != "") {
                params.export_desc = this.filter.exportDesc;
            }
            params.wallet_type = "TRANSACTION_V2";
            params = this.convert(params);
            this.$bvModal.hide("export-desc");
            this.$bus.$emit("show-loading", true);
            ReconcileRepository.exportUserTransactions(params)
                .then((response) => {
                    if (response.data.error_code) {
                        this.notifyAlert("warn", response.data.message);
                    } else {
                        this.notifyAlert(
                            "success",
                            "Tạo tiến trình thành công. Vui lòng chờ"
                        );
                    }
                    this.$bus.$emit("show-loading", false);
                })
                .catch(() => {
                    this.notifyAlert("warn", "Có lỗi xảy ra");
                });
        },
        handleInput() {
            if (!this.filter.start_date) {
                this.filter.start_date = this.firstDayOfMonth();
            }
            if (!this.filter.end_date) {
                this.filter.end_date = this.lastDayOfMonth();
            }
        },
        convert(params) {
            for (let key in params) {
                if (params[key] == "" || params[key] == null) {
                    delete params[key];
                }
            }
            return params;
        },
    },
    watch: {
        currentPage() {
            this.getList();
        },
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
input[type="text"],
input[type="date"],
select {
    min-height: 43px;
}
</style>
